import { navigate } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../compontents/Layout/Layout';

type ThanksNewsletterPageProps = {
    location: any;
};

const ThanksNewsletterPage: React.FC<ThanksNewsletterPageProps> = ({ location }) => {
    return (
        <Layout location={location}>
            <Helmet>
                <title>Thanks You | Newsletter Sign Up</title>
                <meta name="robots" content="noindex, nofollow"></meta>
            </Helmet>

            <div className="col-lg-6 col-md-10 mx-auto mb-5" data-testid="thankyou-container">
                <img
                    className="img-fluid"
                    src="../images/thanks-sign-up.jpg"
                    alt="thank you for signing up for the newsletter"
                />
                <p className="mainText center mt-5 px-3">
                    You&apos;ll receive future reports and industry insights from SquareHook, as well as promotional
                    offers and news. You can unsubscribe at any time.
                </p>
                <div className="bgsection p-4 mb-5" style={{ border: '1px solid #0092c5' }}>
                    <div className="row">
                        <div className="col-7 my-auto">
                            <p>
                                As an extra thankyou, here&apos;s a free look at our latest report on the benefits of
                                managed IT.
                            </p>
                            <a
                                className="button-primary"
                                href="https://squarehook.com/documents/benefits-of-managed-it.pdf"
                                target="_blank"
                                rel="noreferrer">
                                Free Document
                            </a>
                        </div>
                        <div className="col-5">
                            <a
                                href="https://squarehook.com/documents/benefits-of-managed-it.pdf"
                                target={'_blank'}
                                rel="noreferrer">
                                <img
                                    style={{ cursor: 'pointer' }}
                                    className="img-fluid"
                                    src="../images/managed-it-doc.png"
                                />
                            </a>
                        </div>
                    </div>
                </div>
                <p className="center">
                    <a className="button-primary" onClick={() => navigate(-1)}>
                        Go Back
                    </a>
                </p>
            </div>
        </Layout>
    );
};

export default ThanksNewsletterPage;
